<!-- Layout sobre el que se monta la aplicacion -->
<!-- Sustituye el BaseLayout de Pui -->
<template>
	<v-app class="pui-baseLayout">
		<v-layout>
			<router-view />
		</v-layout>
		<pui-cookies-bar class="pui-cookies" />
		<pui-loader />
		<pui-expired-session />
	</v-app>
</template>

<script>
export default {
	name: 'Layout',
	data: () => ({}),
	methods: {},
	created() {}
};
</script>

<style lang="scss">
.pui-cookies + .v-banner {
	background: rgba(86, 85, 103, 0.85) !important;

	& > .v-banner__wrapper {
		height: 4.5rem;
		& > .v-banner__content {
			& > .v-banner__icon {
				display: none;
			}
			& > .v-banner__text {
				color: #fff;

				& > span {
					color: #fff;
					padding-left: 10%;
				}
			}
		}

		& > .v-banner__actions {
			padding-right: 10%;

			& > .v-btn.secondary--text {
				background-color: #2498ff !important;
				border-radius: 8px !important;
				color: #fff !important;
			}

			& > .v-btn.primary--text {
				background-color: #fff !important;
				border-radius: 8px !important;
				color: #575e60 !important;
			}
		}
	}
}
</style>
